require('./bootstrap');
require('./bwpublisher');

$(window).on('resize', function () {
	pinFooter();

	if ($(window).outerWidth() >= 941) {
		$('.page-overlay').removeClass('mobile-nav');
		$('header nav, .mobile-nav, header nav button').removeClass('active');
		$('header nav ul[style]').removeAttr('style');
	}

	if ($(window).outerWidth() >= 769 && $(window).outerWidth() <= 1024) {
		resizeMasonryTiles();
	} else {
		scaleTiles('.tiles.masonry .tile');
	}
});

$(function () {
	pinFooter();

	if ($(window).outerWidth() >= 769 && $(window).outerWidth() <= 1024) {
		resizeMasonryTiles();
	} else {
		scaleTiles('.tiles.masonry .tile');
	}

	// Menu
	$('.mobile-nav').on('click', function (e) {
		e.preventDefault();

		$(this).add('header nav').toggleClass('active');
		$('.page-overlay').toggleClass('mobile-nav');
	});

	$('header nav button').on('click', function () {
		$(this).toggleClass('active');
		$(this).next('ul').slideToggle();
	});

    // Search
    $('.search, #search .close').on('click', function() {
        $('#search').toggleClass('active');
		setTimeout(function () {
			$('#term').trigger('focus');
		}, 250);
    });

	// Animated Labels
	$('.animated-label :input').each(function () {
		if ($.trim($(this).val())) {
			$(this).parents('.animated-label').addClass('focused');
		}
	}).on('focus', function () {
		$(this).parents('.animated-label').addClass('focused');
	}).on('blur', function () {
		if (! $.trim($(this).val())) {
			$(this).parents('.animated-label').removeClass('focused');
		}
	});

	// Modals
	$('.modal .close').on('click', function () {
		$('.modal, .page-overlay').removeClass('active');
	});

	// Back to Top
	$('footer .top').on('click', function () {
		$('html, body').animate({
			scrollTop: 0
		}, '300');
	});

	// Forms
	let $num = new Intl.NumberFormat();
	$('.range').on('input', function () {
		positionBubble($(this));
	}).trigger('input');

	function positionBubble($this) {
		let $val = $this.val(),
			$min = $this.attr('min') ? $this.attr('min') : 0,
			$max = $this.attr('max') ? $this.attr('max') : 0,
			$unit = $this.attr('unit'),
			$output = $this.hasClass('prefix') ? $unit + ' ' + $num.format($val) : $num.format($val) + ' ' + $unit,
			$pos = Number((($val - $min) * 100) / ($max - $min)),
			$offset = 10 - ($pos * 0.2);

		$this.siblings('.output-value').text($output);
		$this.siblings('.output-value.bubble').css('left', 'calc(' + $pos + '% + ' + $offset + 'px)');
	}

	$('.flatpickr:not([disabled], [readonly])').each(function () {
		let $allowInput = $(this).data('allow-input') || null,
			$minDate = $(this).data('min-date') ? $(this).data('min-date') : null,
			$maxDate = $(this).data('max-date') ? $(this).data('max-date') : null;

		flatpickr($(this)[0], {
			dateFormat: 'd/m/Y',
			minDate: $minDate,
			maxDate: $maxDate,
			allowInput: $allowInput,
			monthSelectorType: 'static'
		});
	});

	$('.optional.row, .optional.cols-1, .optional.cols-2, .optional.cols-3, .optional.cols-4').css('display', 'flex').hide();

	$('select[data-optional]').on('change', function () {
		let $id = $(this).attr('id'),
			$val = $(this).val() || null,
			$opts = $(this).data('optional');

		hideOptional($id, $opts);

		if ($val && $opts.includes($val)) {
			$('.' + $id + '-' + $val.toLowerCase()).fadeIn();
		}
	}).trigger('change');

	showHidePostalAddress();

	$('input#same_as_residential').on('change', function () {
		showHidePostalAddress();
	});

	// Replace CSS hover with JS for iOS interactions
	$('.info-hover').on('touchend', function () {
		$(this).toggleClass('active');
	}).on('mouseover', function () {
		$(this).addClass('active');
	}).on('mouseout', function () {
		$(this).removeClass('active');
	});

	// Toggle
	$(document).on('click', '.toggle button:not(.active)', function () {
		$('.toggle button').removeClass('active');
		$(this).addClass('active');
	});
});

function pinFooter() {
	let $contentHeight = 0;

	// Don't include the header, as the .page-header accounts for this
	// if ($('header').length) {
	// 	$contentHeight += $('header').outerHeight(true);
	// }

	if ($('footer').length) {
		$contentHeight += $('footer').outerHeight(true);
	}

	if ($('.page-header').length) {
		$contentHeight += $('.page-header').outerHeight(true);
	}

	// Dynamically calculate the height for all visible siblings
	// $('main').siblings(':visible').each(function() {
	// 	if ($(this).css('opacity') > 0) {
	// 		$contentHeight += $(this).outerHeight(true);
	// 	}
	// });

	$('main').css('min-height', 'calc(100vh - ' + $contentHeight + 'px)');
}

function resizeMasonryTiles() {
	let $lrg = $('.tiles.masonry .tiles-row:nth-child(2n+1) .tile:nth-child(3n+1), .tiles.masonry .tiles-row:nth-child(2n+2) .tile:nth-child(3n+3)'),
		$smlOdd = $('.tiles.masonry .tiles-row:nth-child(2n+1) .tile:nth-child(3n+2), .tiles.masonry .tiles-row:nth-child(2n+1) .tile:nth-child(3n+3)'),
		$smlEven = $('.tiles.masonry .tiles-row:nth-child(2n+2) .tile:nth-child(3n+1), .tiles.masonry .tiles-row:nth-child(2n+2) .tile:nth-child(3n+2)');

	scaleTiles($lrg);

	$smlOdd.each(function () {
		var $lrgSibling = $(this).closest('.tiles-row').find('> :first-child');
		$(this).outerHeight(($lrgSibling.outerWidth() - 30) / 2);
	});

	$smlEven.each(function () {
		var $lrgSibling = $(this).closest('.tiles-row').find('> :last-child');
		$(this).outerHeight(($lrgSibling.outerWidth() - 30) / 2);
	});
}

function scaleTiles($tar) {
	$($tar).each(function () {
		$(this).height($(this).width());
	});
}

function hideOptional($id, $opts) {
	for (let i = 0; i < $opts.length; i++) {
		let $opt = $opts[i].toLowerCase(),
			$children = $('.' + $id + '-' + $opt + ' select[data-optional]');

		$('.' + $id + '-' + $opt).fadeOut();
		// Reset all releveant optional fields
		// $('.' + $id + '-' + $opt + ' :input').each(function () {
		// 	$(this).val('').trigger('blur');
		// 	if ($(this).hasClass('selectize')) {
		// 		window[$(this).attr('id') + '_selectize'].clear();
		// 	}
		// });

		if ($children.length) {
			$.each($children, function () {
				let $childId = $(this).attr('id'),
					$childOpts = $(this).data('optional');
				hideOptional($childId, $childOpts);
			});
		}
	}
}

function showHidePostalAddress() {
	if ($('input#same_as_residential').is(':checked')) {
		$('.postal-address').slideUp();
	} else {
		$('.postal-address').slideDown();
	}
}

// Validator Settings
$.validator.addMethod('length', function (value, element, param) {
	return this.optional(element) || value.length == param;
}, 'Please enter a {0} digit number');

$.validator.addMethod('emailAddress', function (value, element) {
	re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return this.optional(element) || re.test(value);
}, 'Please enter a valid email address');

$.validator.addMethod('numberSpace', function (value, element) {
	re = /^[\d\s]+$/;
	return this.optional(element) || re.test(value);
}, 'Please enter numbers or spaces only');

$.validator.addMethod('minDOB', function (value, element) {
	var minDate = new Date();
	var inputDate = new Date(toDate(value));

	newYear = minDate.getFullYear() - 18;
	minDate.setFullYear(newYear);

	if (inputDate < minDate) {
		return true;
	}

	return false;
}, 'Must be at least 18 years old');

$.validator.addMethod('minEXP', function (value, element) {
	var minDate = new Date();
	var inputDate = new Date(toDate(value));

	if (inputDate < minDate) {
		return false;
	}

	return true;
}, 'Expiration must be in the future');

$.validator.addMethod('groupNotZero', function (value, element, param) {
	var $count = 0;

	$(param).each(function () {
		$count += $(this).val();
	});

	return this.optional(element) || $count > 0;
}, 'Both Years and Months cannot be 0');

$.validator.addMethod('alphanumericstrict', function (value, element) {
	return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
}, 'Please enter numbers or letters only');

$.validator.addMethod('exactlength', function (value, element, param) {
	return this.optional(element) || value.length == param;
}, 'Please enter exactly {0} characters');

function toDate(dateStr) {
	var parts = dateStr.split('/');
	return new Date(parts[2], parts[1] - 1, parts[0]);
}

// Validation Defaults
jQuery.validator.setDefaults({
	errorElement: 'span',
	normalizer: function (value) {
		return $.trim(value);
	},
	errorPlacement: function (error, element) {
		if (element.hasClass('group')) {
			element.closest('.radiochecks').after(error);
		} else if (element.attr('data-rule-require_from_group') && (element.attr('type') == 'checkbox' || element.attr('type') == 'radio')) {
			element.closest('.radiochecks').after(error);
		} else if (element.attr('type') == 'checkbox') {
			element.next('label').after(error);
		} else if (element.attr('type') == 'radio') {
			element.closest('.radiochecks').after(error);
		} else if (element.parents('.selectize').length) {
			element.closest('.selectize-control').after(error);
		} else if (element.hasClass('selectized')) {
			element.next('.selectize-control').after(error);
		} else {
			error.insertAfter(element);
		}
	},
	showErrors: function (errorMap, errorList) {
		this.defaultShowErrors();

		if ($('.tabs dt.active').length) {
			$('.tabs dt.active button').trigger('click');
		}
	},
	ignore: ':hidden:not(.selectized), :hidden > .selectized, .selectize-control .selectize-input input',
	submitHandler: function (form) {
		resetOptional(form);
		form.submit();
	}
});

window.resetOptional = function (form) {
	$(form).find('.optional :input:hidden').each(function () {
		$(this).val('');
	});
}
